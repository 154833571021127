































































import { Component } from 'vue-property-decorator'
import Pagination from '@/shared/components/data-table/Pagination.vue';
import __ from '@/helpers/__';
import IRequestItem from '@/modules/requests/interfaces/request-item.interface';
import IResponse, { IMeta } from '@/shared/interfaces/response.interface';
import { GeneralRoutes } from '@/router/routes/general';
import http from '@/shared/helpers/http';
import IModelResponse from '@/shared/interfaces/model-response.interface';
import IRequestResponse from '@/modules/requests/interfaces/request-response.interface';
import moment from 'moment';
import TableLoader from '@/shared/components/data-table/TableLoader.vue';
import _ from 'lodash';
import SelectOption from '@/shared/classes/components/form/select-option';
import { PerPageOptions } from '@/shared/configs/per-page-options';
import { tableCount } from '@/helpers/tableCount';
import StickyTableHeader from '@/shared/components/data-table/StickyTableheader.vue';

@Component({
  components: { TableLoader, Pagination },
  methods: { __ }
})
export default class ClientRequests extends StickyTableHeader {
  private fields: TableField[] = [
    { key: 'description', label: __('views.requests.form.description'), sortable: true },
    { key: 'manager', label: __('views.requests.form.manager'), sortable: true },
    { key: 'status', label: __('views.requests.form.status'), sortable: true }
  ]

  private perPageOptions: SelectOption[] = PerPageOptions
  private items: IRequestItem[] = []
  private meta: IMeta | undefined | null = null
  private loading: boolean = true
  private perPage = 10
  GeneralRoutes = GeneralRoutes

  created(): void {
    tableCount.getRowCount(tableCount.tableID.clientRequests).then((perPage: any) => {
      this.perPage = perPage
      this.fetchRequests()
    })

    this.$root.$on('formSuccess', () => {
      this.fetchRequests()
    })
  }

  perPageChange(): void {
    tableCount.setRowCount(tableCount.tableID.clientRequests, this.perPage)
    if (this.$route.query?.page) {
      this.$router.replace({ query: _.omit(this.$route.query, 'page') })
    }
    this.fetchRequests()
  }

  private fetchRequests(): void {
    this.loading = true
    const page = this.$route.query.page || 1
    http
      .get('/requests', { params: { per_page: this.perPage, page, 'filter[equals.client_id]': this.$route.params.id } })
      .then((response: IResponse<IModelResponse>) => {
        this.meta = response.data.meta
        const processedItems: IRequestItem[] = []
        response.data.data.forEach((item: IRequestResponse) => {
          processedItems.push({
            id: item.id,
            source: item.source_title,
            name: item.client_name,
            phone: item.client === null ? item.phone : item.client.phone,
            email: item.client === null ? item.email : item.client.company_email,
            description: this.trimText(item.description),
            manager: item.manager === null ? '' : item.manager.full_name,
            statusTitle: item.status_title,
            status: item.status,
            createdAt: moment(item.created_at).format('YYYY-MM-DD')
          })
        })
        this.items = processedItems
      })
      .finally(() => {
        this.loading = false
        this.setStickyHeader()
      })
  }

  private trimText(text: string): string {
    text = text.replace(/(<([^>]+)>)/gi, '').replace(new RegExp('&nbsp;', 'g'), ' ')
    if (text.length > 100) {
      return text.slice(0, 96) + '...'
    }
    return text
  }

  private rowClick(item: IRequestItem): void {
    this.$router.push({ name: GeneralRoutes.requestInner, params: { id: item.id.toString() } })
  }

  get showTableFooter():boolean {
    if (!this.meta) return false
    return this.meta.total > this.perPage
  }

  private addNewRequest() {
    this.$router.push({ name: GeneralRoutes.requestCreate, params: { clientId: this.$route.params.id } })
  }
}

interface TableField {
  key: string
  label: string
  sortable: boolean
}

