







































































































import { Component, Vue } from 'vue-property-decorator'
import __ from '@/helpers/__'
import Form from '@/shared/components/form/Form.vue'
import { FieldSizes } from '@/shared/classes/components/form/field';
import { mapGetters } from 'vuex';
import { UserGetters } from '@/store/modules/user/user.getters';
import User from '@/modules/user/models/user.model';
import ClientNotFound from '@/shared/components/ClientNotFound.vue';
import PageHeader from '@/components/layout/PageHeader.vue';
import { GeneralRoutes } from '@/router/routes/general';
import { IProjectPayload } from '@/modules/project/interfaces/project-response.interface';
import SelectOption from '@/shared/classes/components/form/select-option';
import {
  ProjectStatusKeys,
  ProjectStatusKeysData, ProjectTypeKeys, ProjectTypeKeysData
} from '@/shared/configs/project.config';
import ProjectService from '@/services/ProjectService';

@Component({
  components: { PageHeader, ClientNotFound, Form },
  methods: { __ },
  computed: {
    ...mapGetters({
      user: UserGetters.getUser
    })
  }
})
export default class ProjectsCreate extends Vue {
  FieldSizes = FieldSizes
  error: any = null
  user!: User

  private form: IProjectPayload | null = null

  private statusOptions: SelectOption[] = [
    new SelectOption().setKey(ProjectStatusKeys.active).setTitle(ProjectStatusKeysData[ProjectStatusKeys.active]),
    new SelectOption().setKey(ProjectStatusKeys.disabled).setTitle(ProjectStatusKeysData[ProjectStatusKeys.disabled])
  ]

  private typeOptions: SelectOption[] = [
    new SelectOption().setKey(ProjectTypeKeys.contract).setTitle(ProjectTypeKeysData[ProjectTypeKeys.contract]),
    new SelectOption().setKey(ProjectTypeKeys.programing_contract).setTitle(ProjectTypeKeysData[ProjectTypeKeys.programing_contract]),
    new SelectOption().setKey(ProjectTypeKeys.warranty).setTitle(ProjectTypeKeysData[ProjectTypeKeys.warranty]),
    new SelectOption().setKey(ProjectTypeKeys.without_contract).setTitle(ProjectTypeKeysData[ProjectTypeKeys.without_contract])
  ]

  created(): void {
    this.form = {
      client_id: Number(this.$route.params.clientId) || 0,
      url: '',
      valid_until: '',
      status: '',
      cms_type: '',
      month_price: 0,
      type: '',
      comment: '',
      price_per_hour: 0
    }
  }

  submit(): void {
    if (!this.form) {
      return
    }
    ProjectService.createProject(this.form)
      .then(() => {
        this.$router.push({ name: GeneralRoutes.projectsIndex })
      })
      .catch((error: any) => (this.error = error))
  }
}
