







































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import PageHeader from '@/components/layout/PageHeader.vue'
import __ from '@/helpers/__'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/components/form/form-base'
import { FieldSizes } from '@/shared/classes/components/form/field'
import getAll from '@/shared/configs/vuex/get-all.config'
import { mapGetters } from 'vuex'
import { UserGetters } from '@/store/modules/user/user.getters'
import InvoiceTemplate from './models/invoice-template.model'
import { GeneralRoutes } from '@/router/routes/general'
import SelectOption from '@/shared/classes/components/form/select-option';
import { InvoiceTypes } from '@/shared/configs/invoice/invoice.config';
import _ from 'lodash';
import InvoicesService from '@/services/InvoicesService';
import { IServiceItem } from '@/modules/invoices/interfaces/invoice-response.interface';
import IServiceResponse from '@/modules/service/interfaces/service-response.interface';
import LoadingSpinner from '@/shared/components/LoadingSpinner.vue';
import RadioField from '@/shared/components/form/fields/RadioField.vue';
import DropdownField from '@/shared/components/form/fields/DropdownField.vue';

@Component({
  components: { DropdownField, RadioField, LoadingSpinner, PageHeader, Form },
  methods: { __ },
  computed: {
    ...mapGetters({
      user: UserGetters.getUser
    })
  }
})
export default class InvoiceTemplateEdit extends Vue {
  itemsBlockKey: number = 0
  form: FormBase | any = null
  error: any = null
  loading: boolean = true
  data: InvoiceTemplate = new InvoiceTemplate({})
  FieldSizes = FieldSizes
  InvoiceTypes = InvoiceTypes
  vatOptions: SelectOption[] = []
  unitOptions: SelectOption[] = []
  discountTypes: SelectOption[] = [
    new SelectOption().setKey('none').setTitle(__('views.invoices.form.no_discount')),
    new SelectOption().setKey('discount').setTitle(__('views.invoices.form.sum_discount')),
    new SelectOption().setKey('discount_percentage').setTitle(__('views.invoices.form.percent_discount'))
  ]

  async submit(): Promise<void> {
    const payload = _.cloneDeep(this.data)
    payload.discount = payload.discount || 0
    payload.discount_percentage = payload.discount_percentage || 0
    await InvoicesService.updateInvoiceTemplate(payload, payload.id)
      .then(() => {
        this.$router.push({ name: GeneralRoutes.invoiceTemplatesIndex })
      })
      .catch((error: any) => (this.error = error))
  }

  async getInvoiceItemOptions(): Promise<void> {
    const vatOptions = await InvoicesService.getVatOptions()
    vatOptions.forEach(vatItem => {
      this.vatOptions.push(new SelectOption().setKey(vatItem.percentage).setTitle(vatItem.title))
    })

    const unitOptions = await InvoicesService.getUnitOptions()
    unitOptions.forEach(unitItem => {
      this.unitOptions.push(new SelectOption().setKey(unitItem.title).setTitle(unitItem.title))
    })
  }

  onItemChange(data:IServiceItem | string, item:IServiceResponse):void {
    if (typeof data === 'string') {
      if (typeof item?.title !== 'undefined') {
        item.title = data
      }
      return
    }
    if (typeof data?.amount !== 'undefined') {
      item.amount = data.amount
    }
    if (typeof data?.vat !== 'undefined') {
      item.vat = Number(data.vat)
    }
    if (typeof data?.price_per_unit !== 'undefined') {
      item.price_per_unit = data.price_per_unit
    }
    if (typeof data?.price_per_unit_with_vat !== 'undefined') {
      item.price_per_unit_with_vat = Number(data.price_per_unit_with_vat)
    }
    const foundUnit = this.unitOptions.find(unit => unit.title === data?.unit)
    item.unit = foundUnit?.key || item.unit
  }

  removeInvoiceItem(index: number): void {
    this.itemsBlockKey++
    this.data.items.splice(index, 1)
  }

  addInvoiceItem() {
    this.data.items.push({ ...this.data.newInvoiceItem })
  }

  calculateSums() {
    if (!this.data) {
      return
    }
    let newTotal = 0
    let newTotalWithVat = 0
    let newTotalVat = 0
    const vatRate = (this.data.items.length ? this.data.items[0].vat : 0) || 0
    this.data.items.forEach(item => {
      const itemTotal = Number(item.amount) * Number(item.price_per_unit)
      const itemTotalWithVat = itemTotal * (1 + Number(item.vat) / 100)
      item.total = itemTotal.toFixed(2)

      item.total_with_vat = itemTotalWithVat.toFixed(2)
      newTotal += itemTotal
      newTotalWithVat += itemTotalWithVat
      newTotalVat += (itemTotalWithVat - itemTotal)
    })
    const sumDiscount = this.data.discount_type === 'discount' ? Number(this.data.discount) : 0
    const percentDiscount = this.data.discount_type === 'discount_percentage' ? 1 - (Number(this.data.discount_percentage) / 100) : 1
    this.data.total = ((newTotal - sumDiscount) * percentDiscount).toFixed(2)
    this.data.total_with_vat = ((newTotalWithVat - sumDiscount * (1 + vatRate / 100)) * percentDiscount).toFixed(2)
    this.data.total_vat = ((newTotalVat - sumDiscount * (vatRate / 100)) * percentDiscount).toFixed(2)
  }

  @Watch('data.items', { deep: true })
  @Watch('data.discount_type')
  @Watch('data.discount')
  @Watch('data.discount_percentage')
  onItemsChange(): void {
    this.calculateSums()
  }

  async getInvoiceTemplate() {
    const invoiceData = await InvoicesService.getInvoiceTemplate(Number(this.$route.params.id))
    this.data = new InvoiceTemplate(invoiceData)
  }

  async created(): Promise<void> {
    await getAll(this.$store)
    await this.getInvoiceItemOptions()
    await this.getInvoiceTemplate()
    this.loading = false
  }
}
