
























































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import __ from '@/helpers/__'
import PageHeader from '@/components/layout/PageHeader.vue'
import Form from '@/shared/components/form/Form.vue'
import { FieldSizes } from '@/shared/classes/components/form/field'
import Invoice from '@/modules/invoices/models/invoice.model'
import { GeneralRoutes } from '@/router/routes/general'
import IResponse from '@/shared/interfaces/response.interface'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import http from '@/shared/helpers/http'
import _ from 'lodash'
import getAll from '@/shared/configs/vuex/get-all.config'
import LoadingSpinner from '@/shared/components/LoadingSpinner.vue';
import { mapGetters } from 'vuex';
import { UserGetters } from '@/store/modules/user/user.getters';
import User from '@/modules/user/models/user.model';
import SelectOption from '@/shared/classes/components/form/select-option';
import InvoicesService from '@/services/InvoicesService';
import { IServiceItem } from '@/modules/invoices/interfaces/invoice-response.interface';
import IServiceResponse from '@/modules/service/interfaces/service-response.interface';
import RadioField from '@/shared/components/form/fields/RadioField.vue';
import { InvoiceTypes } from '@/shared/configs/invoice/invoice.config';
import InvoicesRegenerateSalesDeed from '@/modules/invoices/InvoicesRegenerateSalesDeed.vue';
import DropdownField from '@/shared/components/form/fields/DropdownField.vue';

@Component({
  components: { DropdownField, RadioField, PageHeader, Form, LoadingSpinner },
  methods: { __ },
  computed: {
    ...mapGetters({
      user: UserGetters.getUser
    })
  }
})
export default class InvoiceEdit extends Vue {
  validNumber: boolean = true
  itemsBlockKey: number = 0
  FieldSizes = FieldSizes
  GeneralRoutes = GeneralRoutes
  InvoiceTypes = InvoiceTypes
  loadingNumber: boolean = false
  user!: User
  error: any = null
  data: Invoice | null = new Invoice({})
  vatOptions: SelectOption[] = []
  unitOptions: SelectOption[] = []
  loading: boolean = true
  discountTypes: SelectOption[] = [
    new SelectOption().setKey('none').setTitle(__('views.invoices.form.no_discount')),
    new SelectOption().setKey('discount').setTitle(__('views.invoices.form.sum_discount')),
    new SelectOption().setKey('discount_percentage').setTitle(__('views.invoices.form.percent_discount'))
  ]

  onSubmitClick() {
    if (!this.data) {
      return
    }
    this.data.need_regenerate_sale_deed = false
    if (!this.data.add_sale_deed) {
      this.submit()
      return
    }
    this.$store.commit('setModalDialog', {
      component: InvoicesRegenerateSalesDeed,
      id: this.data.id,
      props: {
        title: __('views.invoices.regenerate.title', { invoice: this.data.number }),
        size: 'md',
        submitLabel: __('views.invoices.regenerate.yes'),
        cancelLabel: __('views.invoices.regenerate.no'),
        onSuccess: () => {
          if (this.data) {
            this.data.need_regenerate_sale_deed = true
          }
          this.submit()
        },
        onCancel: () => {
          this.submit()
        }
      }
    })
  }

  async submit(submitAsFullInvoice: boolean = false): Promise<void> {
    if (!this.data) {
      return
    }
    const payload = _.cloneDeep(this.data)
    payload.discount = payload.discount || 0
    payload.discount_percentage = payload.discount_percentage || 0
    if (submitAsFullInvoice) {
      payload.type = InvoiceTypes.standard
    }
    await InvoicesService.updateInvoice(payload, Number(this.$route.params.id))
      .then((invoice: Invoice) => {
        if (invoice.id) this.$router.push({ name: GeneralRoutes.invoice, params: { id: invoice.id.toString() } })
      })
      .catch((error: any) => (this.error = error))
  }

  private debouncedCheckIfValid = _.debounce(() => this.checkIfValid(), 400)

  debouncedValidityCheck() {
    this.debouncedCheckIfValid()
  }

  onItemChange(data:IServiceItem | string, item:IServiceResponse):void {
    if (typeof data === 'string') {
      if (typeof item?.title !== 'undefined') {
        item.title = data
      }
      return
    }
    if (typeof data?.amount !== 'undefined') {
      item.amount = data.amount
    }
    if (typeof data?.vat !== 'undefined') {
      item.vat = Number(data.vat)
    }
    if (typeof data?.price_per_unit !== 'undefined') {
      item.price_per_unit = data.price_per_unit
    }
    if (typeof data?.price_per_unit_with_vat !== 'undefined') {
      item.price_per_unit_with_vat = Number(data.price_per_unit_with_vat)
    }
    const foundUnit = this.unitOptions.find(unit => unit.title === data?.unit)
    item.unit = foundUnit?.key || item.unit
  }

  async getInvoiceItemOptions(): Promise<void> {
    const vatOptions = await InvoicesService.getVatOptions()
    vatOptions.forEach(vatItem => {
      this.vatOptions.push(new SelectOption().setKey(vatItem.percentage).setTitle(vatItem.title))
    })

    const unitOptions = await InvoicesService.getUnitOptions()
    unitOptions.forEach(unitItem => {
      this.unitOptions.push(new SelectOption().setKey(unitItem.title).setTitle(unitItem.title))
    })
  }

  removeInvoiceItem(index: number): void {
    if (!this.data) {
      return
    }
    this.data.items.splice(index, 1)
    this.itemsBlockKey++
  }

  @Watch('data.items', { deep: true }) onItemsChange(items: IServiceResponse[]): void {
    if (!items || !items.length) {
      return
    }
    items.forEach(item => {
      item.total = (Number(item.amount) * Number(item.price_per_unit)).toFixed(2)
      item.total_with_vat = (Number(item.amount) * Number(item.price_per_unit) * (1 + Number(item.vat) / 100)).toFixed(2)
    })
  }

  async getInvoice() {
    const invoiceData = await InvoicesService.getInvoice(Number(this.$route.params.id))
    this.data = new Invoice(invoiceData)
  }

  calculateSums() {
    if (!this.data) {
      return
    }
    let newTotal = 0
    let newTotalWithVat = 0
    let newTotalVat = 0
    const vatRate = (this.data.items.length ? this.data.items[0].vat : 0) || 0
    this.data.items.forEach(item => {
      const itemTotal = Number(item.amount) * Number(item.price_per_unit)
      const itemTotalWithVat = itemTotal * (1 + Number(item.vat) / 100)
      item.total = itemTotal.toFixed(2)

      item.total_with_vat = itemTotalWithVat.toFixed(2)
      newTotal += itemTotal
      newTotalWithVat += itemTotalWithVat
      newTotalVat += (itemTotalWithVat - itemTotal)
    })
    const sumDiscount = this.data.discount_type === 'discount' ? Number(this.data.discount) : 0
    const percentDiscount = this.data.discount_type === 'discount_percentage' ? 1 - (Number(this.data.discount_percentage) / 100) : 1
    this.data.total = ((newTotal - sumDiscount) * percentDiscount).toFixed(2)
    this.data.total_with_vat = ((newTotalWithVat - sumDiscount * (1 + vatRate / 100)) * percentDiscount).toFixed(2)
    this.data.total_vat = ((newTotalVat - sumDiscount * (vatRate / 100)) * percentDiscount).toFixed(2)
  }

  @Watch('data.items', { deep: true })
  @Watch('data.discount_type')
  @Watch('data.discount')
  @Watch('data.discount_percentage')
  onDiscountChange(): void {
    this.calculateSums()
  }

  async created(): Promise<void> {
    await getAll(this.$store)
    await this.getInvoiceItemOptions()
    await this.getInvoice()
    this.$root.$emit('headingChange', {
      heading: __(this.data?.type === InvoiceTypes.draft ? 'views.invoices.edit.draft-title' : 'views.invoices.edit.title', { invoices: this.title })
    })
    this.loading = false
  }

  checkIfValid(): any {
    if (!this.loadingNumber && this.data?.number) {
      this.loadingNumber = true
      http
        .get(`invoices/check-is-available/${this.data.number}`)
        .then((response: IResponse<IModelResponse>) => {
          if (!response.data) this.validNumber = false
          else this.validNumber = true
        })
        .finally(() => {
          this.loadingNumber = false
        })
    }
  }

  get title(): string {
    return this.data ? this.data.number : ''
  }

  addInvoiceItem() {
    if (!this.data) {
      return
    }
    this.data.items.push({ ...this.data.newInvoiceItem })
  }
}
