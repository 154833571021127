





















































import { Component, Prop, Watch } from 'vue-property-decorator'
import AbstractField from '@/shared/components/form/fields/AbstractField.vue'
import http from '@/shared/helpers/http';
import IResponse from '@/shared/interfaces/response.interface';
import IModelResponse from '@/shared/interfaces/model-response.interface';
import { ILoadItemsProps } from '@/shared/interfaces/form/form.interface';

@Component
export default class DropdownField extends AbstractField {
  @Prop() hint!: string
  @Prop() loadItemsProps!: ILoadItemsProps
  items: DropdownItem[] = []
  loading: boolean = false
  dropdownOpen: boolean = false
  selectedItem: DropdownItem | null = null
  customItem: string | null = null

  @Watch('model') onValueSelect(newVal: string) {
    if (newVal !== this.selectedItem?.title) {
      this.loadData(newVal)
    }
  }

  loadData(query: string = ''): void {
    this.loading = true
    this.items = []

    http
      .get(`${this.loadItemsProps.endpoint}?q=${query}&per_page=999`)
      .then((response: IResponse<IModelResponse>) => {
        this.items = response.data.data
      })
      .finally((): void => {
        this.loading = false
      })
  }

  onInput(item: string) {
    this.$emit('searchableValue', item)
  }

  openDropdown() {
    this.dropdownOpen = true
    // eslint-disable-next-line no-unused-expressions
    document.getElementById(this.id)?.focus()
    if (!this.value || this.value !== this.selectedItem?.title) {
      this.loadData(this.value || undefined)
    }
  }

  itemClick(item: DropdownItem) {
    this.dropdownOpen = false
    this.blurSelection()
    if (this.selectedItem?.id === item.id) {
      this.selectedItem = null
      this.model = null
      return
    }
    this.model = item.title
    this.selectedItem = item
    if (this.model === null) {
      this.$emit('searchableValue', null)
      this.emitChange(null)
      return;
    }
    this.emitChange(item.title)
    this.$emit('searchableValue', item)
  }

  setCustomItem() {
    if (this.model === this.customItem) {
      this.model = null
      this.customItem = null
    } else {
      this.selectedItem = null
      this.customItem = this.model
    }
    this.dropdownOpen = false
    this.blurSelection()
  }

  blurSelection() {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  }

  get showCustomItem(): boolean {
    return this.value && this.selectedItem?.title !== this.value
  }
}

interface DropdownItem {
  id: number
  title: string,
  price_per_unit: string,
  vat: number,
  amount: string,
  price_per_unit_with_vat: string,
  unit: string
}

