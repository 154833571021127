









import { Component, Prop, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/layout/PageHeader.vue'
import __ from '@/helpers/__'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import { GeneralRoutes } from '@/router/routes/general'
import { tableCount } from '@/helpers/tableCount';
import Project from '@/modules/project/models/project.model';
import {
  ProjectStatusKeys,
  ProjectStatusKeysData,
  ProjectTypeKeys,
  ProjectTypeKeysData
} from '@/shared/configs/project.config';
import highlightExpiring from '@/shared/helpers/data-table/highlight-expiring';

@Component({
  components: { DataTable, PageHeader },
  methods: { __ }
})
export default class ProjectsIndex extends Vue {
  @Prop({ default: false }) showFilter?: boolean
  table: DataTableBase | null = null

  addNewProject(): void {
    this.$router.push({ name: GeneralRoutes.projectsCreate, params: { clientId: this.$route.params.id } })
  }

  created(): void {
    this.table = new DataTableBase()
      .setId(tableCount.tableID.clientProjects)
      .setSumCheckboxes(false)
      .setModel(Project)
      .setEndpoint(`/projects?filter[equals.client_id]=${this.$route.params.id}`)
      .setRowClassCallback(highlightExpiring)
      .setRowClickCallback((item: Project) => {
        this.$router.push({ name: GeneralRoutes.projectsEdit, params: { id: item.id.toString() } })
      })
      .setHeaders([
        new DataTableHeader()
          .setKey('client.company_name')
          .setLabel(__('views.projects.index.table.columns.client'))
          .setLink({ link: GeneralRoutes.client, linkParam: 'client.id' }),
        new DataTableHeader()
          .setKey('url')
          .setLabel(__('views.projects.index.table.columns.url'))
          .setHrefLink((data: Project) => data.url)
          .setHrefTarget('_blank'),
        new DataTableHeader()
          .setKey('validUntil')
          .setLabel(__('views.projects.index.table.columns.valid-till'))
          .setCustomText((data: Project) => {
            if (data.type === ProjectTypeKeys.programing_contract) return '-'

            return data.validUntil
          }),
        new DataTableHeader()
          .setKey('type')
          .setLabel(__('views.projects.index.table.columns.type'))
          .setFunctionalClasses((data: Project) => `data-table__status data-table__status--${data.type}`)
          .setCustomText((data: Project) => {
            if (data.type === ProjectTypeKeys.contract) return __(ProjectTypeKeysData[ProjectTypeKeys.contract])
            if (data.type === ProjectTypeKeys.programing_contract) return __(ProjectTypeKeysData[ProjectTypeKeys.programing_contract])
            if (data.type === ProjectTypeKeys.warranty) return __(ProjectTypeKeysData[ProjectTypeKeys.warranty])
            if (data.type === ProjectTypeKeys.without_contract) return __(ProjectTypeKeysData[ProjectTypeKeys.without_contract])

            return ''
          }),
        new DataTableHeader()
          .setKey('cmsType')
          .setLabel(__('views.projects.index.table.columns.cms'))
          .setCustomText((data: Project) => {
            if (data.cmsType === 'wordpress') return 'WordPress'
            if (data.cmsType === 'prestashop') return 'Prestashop'
            if (data.cmsType === 'laravel') return 'Lavavel'
            if (data.cmsType === 'other') return 'kita'

            return ''
          }),
        new DataTableHeader().setKey('monthPrice').setLabel(__('views.projects.index.table.columns.price')),
        new DataTableHeader().setKey('pricePerHour').setLabel(__('views.projects.index.table.columns.price_per_hour')),
        new DataTableHeader()
          .setKey('status')
          .setLabel(__('views.projects.index.table.columns.status'))
          .setCustomText((data: Project) => {
            if (data.status === ProjectStatusKeys.active) return __(ProjectStatusKeysData[ProjectStatusKeys.active])
            if (data.status === ProjectStatusKeys.disabled) return __(ProjectStatusKeysData[ProjectStatusKeys.disabled])

            return ''
          })
      ])
  }
}
